import MedicalServicesIcon from "@mui/icons-material/MedicalServices";

const servicesData = [
  {
    id: 1,
    title: "Legal & Immigration",
    mini: "Falcon translation is your trusted partner in the global legal landscape providing precise and conclusive translations.",
    link: "Legal-Immigration",
    icon: "fa-solid fa-scale-balanced icon",
    description:
      "Falcon translation is your trusted partner in the global legal landscape providing precise and conclusive translations. We translate all your legal documents covering all aspects of the legal sector. Several of our translators are DPSI qualified with a specialist focus in law and a number of them are NRPSI registered. We also provide legal translations and an in-house legal expert.",
    readMore: [
      "We deal with different areas of law.",
      "and a wide variety of requests including the following:",
      "Civil Litigation",
      "Commercial Law",
      "Employment Law",
      "Immigration Law",
      "Criminal Law",
      "Commercial Litigation",
      "Family Law",
      "Commercial and Residential Property",
    ],
  },
  {
    id: 2,
    title: "NHS & Healthcare",
    mini: "We deliver error-free translations in medical documents, adhering to UK’s MHRA standards, making complex information accessible to all.",
    link: "NHS-Healthcare",
    icon: "fa-solid fas fa-stethoscope icon",
    description: `We deliver error-free translations in medical documents, adhering to UK’s MHRA standards, making complex information accessible to all. Translating medical documents from PILs to clinical studies and reports employing the healthcare technical terminology to meet the required the highest regulatory standards and advocating the rights to patient to find out all their rights, options, and available support. Our translators prioritize confidentiality and have strict respect for your privacy.   <br />
   <br />
 Our team ensures that medical documents, from PILs to clinical reports, are translated with the highest care, making medical information accessible to all and advocating the rights of patient to find out all their rights, options, and available support. We remove language barriers and bridge between culture in the healthcare field through providing translations that reflect diverse populations.`,
    readMore: [
      "We provide translation services for Medical Professionals: doctors, nurses, and other healthcare providers, ensuring open communication with their patients.",
      "We Provide Certified Translations for a Variety of Medical Documents including the following:",
      "Medical reports and records",
      "Vaccination Records",
      "Consent & Discharge Forms",
      "Hospital Intake Forms",
      "Instructions from medical device manufacturers",
      "Medical research documents",
    ],
  },
  {
    id: 3,
    title: "Education",
    description: `We offer professional translation of research papers, studies, and lectures transcending language barriers for a globally engaging material. Our expertise in scientific terminology enables us to translate research papers and articles, preserving the original content’s integrity for a global audience.   <br />
   <br />
 We make education’s passport to the future linguistically valid, culturally sensitive and globally recognized, semiotically comprehensive, expanding knowledge and opportunities without borders.`,
    link: "Education",
    icon: "fa-solid fa-book-open-reader icon",
    mini: "Services Falcon Translation propels educational ventures forward with expert academic translations. We ensure every research paper.",
    readMore: [
      "We assist international students in clearly communicating their needs and support schools across the UK through a wide range of language services, including the following:",
      "Textbooks",
      "Consent forms",
      "Educational transcripts",
      "Orientation material",
      "School websites",
      "Research papers",
      "Classroom Materials",
      "E-learning Materials",
      "Academic Certificate",
    ],
  },
  {
    id: 4,
    title: "Proofreading and Editorial Services",
    description:
      "Our Proofreading and Editorial Services enhance your translated documents’ precision and clarity. Our proofreaders, experts in British English. ensure grammatical excellence. While our editors craft narratives with compelling style and persuasive tone.",
    link: "Proofreading-Editorial-Services",
    icon: "fa-solid fa-envelope-circle-check icon",
    mini: "Our Proofreading and Editorial Services enhance your translated documents’ precision and clarity. Our proofreaders, experts in British English.",
    readMore: [
      "Final arbiters of readiness, our editorial team perfects your documents, ensuring they’re ready to captivate and inform a global audience.",
      "Our translation ensures polished and perfect documents as we tailor our services to your needs, ensuring compliance with British translation and proofreading standards, and elevating your communication to unmatched excellence.",
    ],
  },
  {
    id: 5,
    title: "official Identifications",
    description: `Falcon Translation specializes in translating identity documents with precision and professionalism to ensure accuracy and clear communication between the different diversified communities and the Local Authorities. 
    <br />
    <br />
    Our translation services meet international standards and meticulously crafted for global recognition and legal compliance. We also provide our services to the local councils. Our Commitment to Quality and Compliance We guarantee accuracy, adherence to British law, and confidentiality, setting us apart with our expertise, cutting-edge technology, and exceptional customer service.`,
    link: "official-Identifications",
    icon: "fa-solid fa-fingerprint icon",
    mini: "Identity Translation Services Falcon Translation specializes in translating key identity documents with precision and professionalism. We ensure passports, driver's licenses.",
    readMore: [
      "Competitive Edge: Our translators’ legal expertise and personalized service provide a competitive advantage, ensuring your identity documents are a bridge to your future.",
      "Our translators are highly qualified and DBS certified, providing a wide range of translation services for various documents, including the following:",
      "Passports",
      "Driving licenses",
      "Birth certificates",
    ],
  },
  {
    id: 6,
    title: "Finance",
    description:
      "We serve our client as a global finance partner offering precise and accurate document translations while ensuring regulatory compliance, and cultural insight, enabling our clients to effectively communication with their global partners and customers. Our translators are always up to date with the global financial terms, terminology and professional understanding of the protocol and systems in both the United Kingdom and abroad.",
    link: "Finance",
    icon: "fa-solid fas fa-coins icon",
    mini: "Financial Translation Services Falcon Translation is your partner in the global finance sector, offering precise and accurate financial document translations.",
    readMore: [
      "We provide our translation services across various aspects of the finance acumen, such as banking, investment, insurance, asset management, and financial technology.",
      "Our services include the following:",
      "Earnings statements",
      "Mergers and stock market analyses",
      "Annual Reports",
      "Business Plans",
      "Investors’ communication",
      "Stock Market Analyses",
    ],
  },
  {
    id: 7,
    title: "Other Professional Specializations",
    description: `Falcon Translation offers specialized services across various sectors, ensuring precision and cultural relevance in all professions, such as marketing and media.
    <br />
    <br />
  <h6 class="d-block market-title">Marketing & Media Translation Services</h6> 
  Working in a highly competitive global market becomes challenging, but with the support of our experienced translators, we provide our clients with up-to-date, hands-on translation services of their marketing and media documents.
   <br />
      <br />

    Our team will be by your side to make your marketing campaign a great success whether locally in the UK or abroad ensuring that your message is correctly conveyed to your target segments while promoting your products’ brands and boosting your company’s image.
`,
    link: "Professional-Specializations",
    icon: "fa-solid fas fa-briefcase icon",
    mini: "Specialized Falcon Translation offers specialized services across various sectors, ensuring precision and cultural relevance in every project.",
    readMore: [
      "We provide translations for the Marketing & Media sectors, considering local cultural sensitivities and compliance with broadcasting codes for the audience.",
      "Our translation services include the following:",
      "Packaging",
      "Surveys",
      "Websites",
      "Social Media Posts",
      "Slogan Adaptation",
      "Posters",
      "Leaflets",
    ],
  },
];

export default servicesData;
