import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import Swal from 'sweetalert2';
import Footer from '../components/Footer/Footer';

export default function Review() {
    const [project, setProject] = useState();
    const [review, setReview] = useState();
    const [project_id, setProjectID] = useState();
    const [rate, setRate] = useState();
    const [errors, setErrors] = useState([]);
    const id = useParams().id;
    
    const getProject = async () => {
        //const response = await axios.get(`https://localhost:3001/api/projects/${id}`);
        const response = await axios.get(`http://localhost:3001/api/projects/${id}`);
        setProject(response.data);
        setProjectID(response.data.id);
    }

    useEffect(() => {
        getProject();
    }, []);

    const submitReview = (e) => {
        e.preventDefault();
        axios.post('http://localhost:3001/api/reviews', {
            //project_id: project_id,
            project_id: id,
            review: review,
            rate: rate
        }).then(() => {
            Swal.fire({
                icon: 'success',
                title: 'Success!',
                text: 'Thank you for your review',
              });
        }).catch((error) => {
            if (error.response && error.response.data && error.response.data.errors) {
                setErrors(error.response.data.errors);
            }
        });
    }

    return (
        <div className='container my-5'>
            <h3>Review Form</h3>
        <p className='fs-3'>Hi, {project?.client_name}</p>
        <form onSubmit={submitReview}>
        {errors.length > 0 && (
              <div className='alert alert-danger' role='alert'>
                <ul className='mb-0'>
                  {errors.map((error, index) => (
                    <li key={index}>{error}</li>
                  ))}
                </ul>
              </div>
            )}
                {/* <input type="text" name="project_id" value={id} /> */}
                <label>Your review</label>
                <textarea className='form-control mb-3' type="text" name="review" onChange={(e) => setReview(e.target.value)}></textarea>
                <label>What is your overall experience?</label>
                <div className='d-flex justify-content-around w-25 my-3'>
                    <i className={`fa-regular fa-face-angry fs-1 ${rate === 1 ? 'selected' : ''}`} onClick={() => setRate(1)}></i>
                    <i className={`fa-regular fa-face-frown fs-1 ${rate === 2 ? 'selected' : ''}`} onClick={() => setRate(2)}></i>
                    <i className={`fa-regular fa-face-meh fs-1 ${rate === 3 ? 'selected' : ''}`} onClick={() => setRate(3)}></i>
                    <i className={`fa-regular fa-face-smile fs-1 ${rate === 4 ? 'selected' : ''}`} onClick={() => setRate(4)}></i>
                    <i className={`fa-regular fa-face-laugh fs-1 ${rate === 5 ? 'selected' : ''}`} onClick={() => setRate(5)}></i>
                </div>
                <input className='btn btn-violet' type="submit" value="Submit" />
        </form>
        <Footer/>
        </div>
        
    )
}
