import React, { useEffect, useState } from "react";
import Footer from "../components/Footer/Footer";

export default function OurStory() {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  const handleResize = () => {
    setIsMobile(window.innerWidth <= 768);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <br></br>
      <section id="rt_about_content">
        <div className="container ">
          <div className="row mb-5">
            <div className="col-sm-12">
              <h1 className="text-center">Our Story</h1>
              <br></br>
              <div className="gothic text-size">
                <p style={{ display: "flex", alignItems: "start" }}>
                  In the realm of global discourse, Falcon Translation stands as
                  a beacon of linguistic mastery of the Translation expertise:
                  efficiency in the accuracy and speed. With the precision of a
                  falcon's strike, we deliver translations that resonate with
                  clarity and intent. Our rapid service is the embodiment of the
                  falcon's swift hunt, ensuring messages are conveyed with
                  prompt elegance.
                </p>
                <p>
                  We commit to a full fledge of professional quality assured
                  service to ensure conveying your messages with a global
                  resonance that preserve your message's integrity and with the
                  highest level of confidentiality.
                </p>
                <p>
                  Falcon Translation is a CIOL accredited Company, an
                  endorsement that signifies our steadfast commitment to
                  linguistic excellence. We are committed to provide:
                </p>
              </div>
              <p
                className="text-size gothic"
                style={{
                  display: "flex",
                  textAlign: "start",
                  justifyContent: "start",
                  alignItems: isMobile ? "start" : "center",
                }}
              >
                <span
                  className="box"
                  style={{
                    marginRight: "8px",
                    marginTop: isMobile ? "5px" : "0",
                  }}
                ></span>
                <span className="text" style={{ flex: "1" }}>
                  Timed delivery respecting our clients' deadline and
                  commitment.
                </span>
              </p>
              <p
                className="text-size gothic"
                style={{
                  display: "flex",
                  textAlign: "start",
                  justifyContent: "start",
                  alignItems: isMobile ? "start" : "center",
                }}
              >
                <span
                  className="box"
                  style={{
                    marginRight: "8px",
                    marginTop: isMobile ? "5px" : "0",
                  }}
                ></span>
                <span className="text" style={{ flex: "1" }}>
                  Human powered professional translations with the highest level
                  of accuracy.
                </span>
              </p>
              <p
                className="text-size gothic"
                style={{
                  display: "flex",
                  textAlign: "start",
                  justifyContent: "start",
                  alignItems: isMobile ? "start" : "center",
                }}
              >
                <span
                  className="box"
                  style={{
                    marginRight: "8px",
                    marginTop: isMobile ? "5px" : "0",
                  }}
                ></span>
                <span className="text" style={{ flex: "1" }}>
                  Secured translation respecting the confidentiality of our
                  clients, in compliance to our code of ethics standards.
                </span>
              </p>
              <br></br>
              <h1 className="fontt">Expertise Areas</h1>
              <br></br>
              <p
                className="text-size gothic"
                style={{
                  display: "flex",
                  textAlign: "start",
                  justifyContent: "start",
                  alignItems: isMobile ? "start" : "center",
                }}
              >
                <span
                  className="box"
                  style={{
                    marginRight: "8px",
                    marginTop: isMobile ? "5px" : "0",
                  }}
                ></span>
                <span className="text" style={{ flex: "1" }}>
                  Legal & Immigration: Trust in our meticulous translations for
                  intricate legal terminology.
                </span>
              </p>
              <p
                className="text-size gothic"
                style={{
                  display: "flex",
                  textAlign: "start",
                  justifyContent: "start",
                  alignItems: isMobile ? "start" : "center",
                }}
              >
                <span
                  className="box"
                  style={{
                    marginRight: "8px",
                    marginTop: isMobile ? "5px" : "0",
                  }}
                ></span>
                <span className="text" style={{ flex: "1" }}>
                  Healthcare: Depend on our prompt, precise translations for
                  essential healthcare communication.
                </span>
              </p>
              <p
                className="text-size gothic"
                style={{
                  display: "flex",
                  textAlign: "start",
                  justifyContent: "start",
                  alignItems: isMobile ? "start" : "center",
                }}
              >
                <span
                  className="box"
                  style={{
                    marginRight: "8px",
                    marginTop: isMobile ? "5px" : "0",
                  }}
                ></span>
                <span className="text" style={{ flex: "1" }}>
                  Education: Expect timely, accurate translations of educational
                  materials.
                </span>
              </p>
              <br></br>
              <h1 className="fontt">Support Services</h1>
              <br></br>
              <p
                className="text-size gothic"
                style={{
                  display: "flex",
                  textAlign: "start",
                  justifyContent: "start",
                  alignItems: isMobile ? "start" : "center",
                }}
              >
                <span
                  className="box"
                  style={{
                    marginRight: "8px",
                    marginTop: isMobile ? "5px" : "0",
                  }}
                ></span>
                <span className="text" style={{ flex: "1" }}>
                  Proofreading: Elevate your documents with our comprehensive
                  proofreading.
                </span>
              </p>
              <p
                className="text-size gothic"
                style={{
                  display: "flex",
                  textAlign: "start",
                  justifyContent: "start",
                  alignItems: isMobile ? "start" : "center",
                }}
              >
                <span
                  className="box"
                  style={{
                    marginRight: "8px",
                    marginTop: isMobile ? "5px" : "0",
                  }}
                ></span>
                <span className="text" style={{ flex: "1" }}>
                  Certification: Obtain certified translations of personal
                  documents swiftly.
                </span>
              </p>
              <p
                className="text-size gothic"
                style={{
                  display: "flex",
                  textAlign: "start",
                  justifyContent: "start",
                  alignItems: isMobile ? "start" : "center",
                }}
              >
                <span
                  className="box"
                  style={{
                    marginRight: "8px",
                    marginTop: isMobile ? "5px" : "0",
                  }}
                ></span>
                <span className="text" style={{ flex: "1" }}>
                  Financial: Secure swift, dependable translations of critical
                  financial records.
                </span>
              </p>
              <br></br>
              <h1 className="fontt">Specializations</h1>
              <br></br>
              <p
                className="text-size gothic"
                style={{
                  display: "flex",
                  textAlign: "start",
                  justifyContent: "start",
                  alignItems: isMobile ? "start" : "center",
                }}
              >
                <span
                  className="box"
                  style={{
                    marginRight: "8px",
                    marginTop: isMobile ? "5px" : "0",
                  }}
                ></span>
                <span className="text" style={{ flex: "1" }}>
                  Propel your career with translations that mirror your
                  professional achievements.
                </span>
              </p>
              <br></br>

              <br></br>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "column",
                  fontFamily: "good",
                  textTransform: "uppercase",
                  textAlign: "center",
                  padding: isMobile ? "25px" : "60px",
                }}
              >
                <p
                  style={{
                    fontSize: "1rem",
                  }}
                >
                  Choose Falcon Translation for reliable, meticulous, and
                  effective translation services.
                </p>
                <p
                  style={{
                    fontSize: "1rem",
                    color: "#4a259b",
                  }}
                >
                  <span
                    style={{
                      fontWeight: "bold",
                    }}
                  >
                    Falcon Translation {isMobile && <br />}
                  </span>{" "}
                  <span
                    style={{
                      fontSize: "12px",
                    }}
                  >
                    Accuracy. Speed. Efficiency
                  </span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <br></br>
      <Footer />
    </>
  );
}
