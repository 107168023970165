import React from "react";
import TermsAndConditionsComponent from "../components/TermsAndConditions/TermsAndConditions";
import Footer from "../components/Footer/Footer";

export default function TermsAndConditions() {
  return (
    <>
      <TermsAndConditionsComponent />
      <Footer />
    </>
  );
}
