import React from "react";
import { useLocation } from "react-router-dom";
import Footer from "../Footer/Footer";
//
export default function ServiceComponent({ data }) {
  const location = useLocation();
  const service = location.state?.service;

  console.log(service);
  return (
    <>
      <section id="rt_about_content">
        <div className="container  ">
          <div className="row mb-5">
            <div className="col-sm-12">
              <br></br>
              <br></br>
              <h1 className="fontt" style={{ textAlign: "center" }}>
                {service.title}
              </h1>
              <br></br>
              <div className="row">
                <div
                  style={{
                    display: "flex",
                    alignItems: "start",
                    flexDirection: "column",
                  }}
                >
                  <p
                    className="text-size gothic"
                    dangerouslySetInnerHTML={{ __html: service.description }}
                  ></p>

                  <div
                    className="text-size gothic"
                    style={{
                      display: "flex",
                      alignItems: "start",
                      flexDirection: "column",
                    }}
                  >
                    <p>{service.readMore?.slice(0, 2).join(" ")}</p>
                    <ul
                      style={{
                        listStyleType: "disc",
                        listStylePosition: "inside",
                      }}
                    >
                      {service.readMore?.slice(2).map((item, idx) => (
                        <p
                          className="text-size gothic"
                          style={{
                            display: "flex",
                            textAlign: "start",
                            justifyContent: "start",
                            alignItems: "center",
                          }}
                        >
                          <span
                            className="box"
                            style={{ marginRight: "8px" }}
                          ></span>
                          <span className="text" style={{ flex: "1" }}>
                            {item}
                          </span>
                        </p>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <br></br>
      <Footer />
    </>
  );
}
