import React, { useEffect, useState } from "react";
import Service from "../components/Service/Service";
import Home from "./Home";
import services from "../lib/data/servicesData";

export default function Services() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <Service />
    </div>
  );
}
