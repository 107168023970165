import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

export default function FalconDetails() {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  const handleResize = () => {
    setIsMobile(window.innerWidth <= 768);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <>
      <div className="mt-4" style={{ fontSize: "0.8rem" }}>
        <h6>Falcon Translation LTD</h6>
        <p className="gothic m-0">
          7 Bell Yard London, Bell Yard, London,{isMobile ? <br /> : ""}{" "}
          England, WC2A 2JR
        </p>
        <p className="gothic m-0">
          Email:{" "}
          <a
            href="mailto:info@falcontranslation.co.uk"
            style={{ color: "black" }}
          >
            info@falcontranslation.co.uk
          </a>
        </p>
        <p className="m-0">
          <Link
            to="/TermsAndConditions"
            style={{
              color: "#4a259b",
              fontSize: "0.8rem",
              textDecoration: "none",
              marginTop: "50px",
              textDecorationLine: "underline",
            }}
          >
            Terms and Conditions
          </Link>
        </p>
      </div>
    </>
  );
}
