import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import FalconDetails from "../FalconDetails";
import { useForm } from "../../hooks/useForm";

export default function Footer({ service }) {
  const navigate = useNavigate();
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const { formValues, handleChange, handleSubmit, isLoading } =
    useForm("/emails");

  const handleQuoteButtonClick = () => {
    const isHomePage = window.location.pathname === "/";
    if (!isHomePage) {
      navigate("/", { state: { triggerQuoteService: true } });
    } else {
      service();
    }
  };

  const handleResize = () => {
    setIsMobile(window.innerWidth <= 768);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <>
      <footer className="py-5">
        <div className="container">
          <div className="row">
            <div className="col-md-6 text-right">
              <form onSubmit={handleSubmit}>
                <div className="form-group mb-3">
                  <label className="gothic" htmlFor="email">
                    Subscribe to our newsletter
                  </label>
                  <input
                    type="email"
                    placeholder="Type your email"
                    className="form-control gothic"
                    id="email"
                    name="email"
                    onChange={handleChange}
                    value={formValues.email || ""}
                  />
                </div>
                <button
                  type="submit"
                  className="btn btn-violet fs-6 py-2 px-3 text-center gothic"
                  disabled={isLoading}
                >
                  {isLoading ? "Subscribing..." : "Subscribe"}
                </button>
              </form>
              {/* Added Falcon Translation LTD details here */}
              <FalconDetails />
            </div>
          </div>
        </div>
        <div style={{ float: "left" }} className="call-buton">
          {isMobile ? (
            <button
              className="cc-whatsto-action-ripple"
              onClick={handleQuoteButtonClick}
              style={{
                borderRadius: "50%",
                width: "80px",
                height: "70px",
                padding: "6px",
                paddingTop: "12px",
                cursor: "pointer",
                border: "none",
              }}
            >
              <span style={{ textAlign: "center" }}>Quote Now</span>
            </button>
          ) : (
            <button
              className="cc-whatsto-action-ripple"
              onClick={handleQuoteButtonClick}
              style={{
                borderRadius: "50%",
                width: "80px",
                height: "70px",
                padding: "6px",
                paddingTop: "12px",
                marginRight: "100px",
                marginBottom: "100px",
                cursor: "pointer",
                border: "none",
                textJustify: "center",
              }}
            >
              <span className="gothic" style={{ textAlign: "center" }}>
                Quote Now
              </span>
            </button>
          )}
        </div>
      </footer>
    </>
  );
}
