import React, { useEffect, useState } from "react";

export default function TermsAndConditionsComponent() {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  const handleResize = () => {
    setIsMobile(window.innerWidth <= 768);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div
      className="  bg-white container"
      style={{
        fontFamily: "gothic",
        paddingTop: isMobile ? "1rem" : "2rem",
        display: "flex",
        flexDirection: "column",
        gap: "2rem",
      }}
    >
      {/* Header */}
      <div
        style={{
          display: "flex",
          justifyContent: isMobile ? "start" : "center",
          alignItems: isMobile ? "start" : "center",
          flexDirection: "column",
        }}
      >
        <h1 className="text-4xl font-bold   text-gray-800">
          Terms & Conditions
        </h1>
        <p>
          Welcome to Falcon Translation LTD. located at {isMobile ? <br /> : ""}
          7 Bell Yard, London WC2 A2JR
        </p>
      </div>

      {/* Terms Content */}
      <div className="text-gray-700 mt-8 space-y-6">
        <section>
          The following terms and conditions may be subject to change from time
          to time. Updates and changes will be regularly posted on Falcon
          website.
        </section>

        {/* Falcon's Obligations & Liabilities Section */}
        <section>
          <h4>1. Falcon's Obligations & Liabilities</h4>
          <p>
            <strong>1.1.</strong> Falcon shall use reasonable endeavours to
            provide the Services, and to deliver the Deliverables to the Client.
          </p>
          <p>
            <strong>1.2.</strong> Falcon shall use reasonable endeavours to meet
            any performance dates specified in the Order, but any such dates
            shall be estimates only and time shall not be of the essence for
            performance of the Services.
          </p>
          <p>
            <strong>1.3.</strong> Falcon has no liability for loss, corruption,
            or interception of original work or deliverables.
          </p>
          <p>
            <strong>1.4.</strong> Falcon is not responsible for checking the
            accuracy(or authenticity) of the original work.
          </p>
          <p>
            <strong>1.5.</strong> Falcon is not responsible for any indirect or
            consequential damages attributable to the Deliverables.
          </p>
          <p>
            <strong>1.6.</strong> Falcon shall not be liable for:
          </p>
          <ul
            style={{
              marginLeft: "2rem",
              listStyleType: "disc",
            }}
          >
            <li>loss of profits;</li>
            <li>loss of business;</li>
            <li>depletion of goodwill and/or similar losses;</li>
            <li>loss of anticipated savings;</li>
            <li>loss of goods;</li>
            <li>loss of contract;</li>
            <li>
              loss or corruption of data or information, and any special,
              indirect, consequential, or pure economic loss, costs, damages,
              charges, or expenses
            </li>
          </ul>
          <p>
            <strong>1.7.</strong> All Services which appear on the Website are
            subject to availability.
          </p>
          <p>
            <strong>1.8.</strong> All Services which appear on the Website are
            subject to availability.
          </p>
          <p>
            <strong>1.9.</strong> For certified, sworn, or accredited
            translations, Falcon (and/or our suppliers) are ultimately
            responsible for the translated text and shall have absolute
            discretion to reject any change requested by the Client which the
            translator feels isn’t a “true and accurate” reflection of the
            original text. No refund will be issued if we reject a Client’s
            change request in these circumstances.
          </p>
          <p>
            <strong>1.10.</strong> Falcon shall not be held liable for any
            delays caused by not directly controlled technological
            malfunctioning such as modems, email and/or other mail or carriers.
          </p>
          <p>
            <strong>1.11.</strong> Falcon shall not be held liable for any
            reason whatsoever for translations which are not stylistically
            satisfactory. Including salability, suitability for a particular
            purpose, ownership, and non-violation of third-party rights.
          </p>
          <p>
            <strong>1.12.</strong> Falcon shall not be held liable for all
            special, indirect, or consequential damages or for any other damages
            of any type resulting from the loss of rights to use, loss of
            information, or lost profits, whether they result from the
            performance of a contract, negligence, or other detrimental actions,
            deriving from or in some way connected with the provided services by
            Falcon.
          </p>
          <p>
            <strong>1.13.</strong> If Falcon’s performance of its obligations
            under the Contract is prevented or delayed by any act or omission of
            the Client, its agents, subcontractors, consultants or employees,
            Falcon shall not be liable for any costs, charges, or losses
            sustained or incurred by the Client arising directly or indirectly
            from such prevention or delay
          </p>
        </section>

        {/* 3. Services Quotations & Fees  */}
        <section>
          <h4>2. Services Quotations & Fees </h4>
          <p>
            <strong>2.1</strong> Unless provided otherwise by the Contract,
            Falcon may deliver Services by instalments; such instalments shall
            be separate obligations and no breach in respect of one or more of
            them shall entitle the Client to cancel any subsequent instalments
            or repudiate the Contract.
          </p>
          <p>
            <strong>2.2</strong> The Client’s Order, or the Client’s acceptance
            of a quotation for Services by Falcon, constitutes an offer by the
            Client to purchase the Services on these Conditions. No offer placed
            by the Client shall be accepted by Falcon other than by a written
            unqualified acceptance issued and executed by Falcon; or (if
            earlier) by Falcon starting to provide the Services when a contract
            for the supply and purchase of the Services on these Conditions will
            be established.
          </p>
          <p>
            <strong>2.3</strong> Quotations are given by Falcon and will be
            valid for a period of 7 days from its date unless stated otherwise,
            provided that Falcon has not previously withdrawn it.
          </p>
          <p>
            <strong>2.4</strong> Quotations are given by Falcon on the basis of
            the Client’s description of the Services and Materials, and any
            other instructions. Such quotations may be amended at any time if
            the description of such materials is materially inaccurate.
          </p>
          <p>
            <strong>2.5</strong> In the case of Services made to the Client’s
            special requirements, it is the Client’s responsibility to ensure
            that any information or specification provided is accurate.
          </p>
          <p>
            <strong>2.6</strong> The Services supplied under the Contract shall
            be provided by Falcon to the Client from the date of acceptance of
            the Contract.
          </p>
          <p>
            <strong>2.7</strong> The Services supplied under the Contract shall
            continue to be supplied for the period stated in the Order.
          </p>
        </section>

        {/* 4. Client's Obligations */}
        <section>
          <h4>3. Client's Obligations</h4>
          <p>
            <strong>3.1</strong> The client shall supply reference documents in
            case they their required translation service covers technical
            documents including drawings or diagrams that provide a better
            understanding of the documents.
          </p>
          <p>
            <strong>3.2</strong> Co-operate with Falcon in all matters relating
            to the Services.
          </p>
          <p>
            <strong>3.3</strong> Have authority to enter the Contract.
          </p>
          <p>
            <strong>3.4</strong> Provide to Falcon, in a timely manner, such
            Original Works and other information as Falcon may reasonably
            require and ensure that it is accurate in all material respects.
          </p>
          <p>
            <strong>3.5</strong> Obtain and maintain all necessary licenses and
            consents and comply with all relevant legislation in relation to the
            Services.
          </p>
          <p>
            <strong>3.6</strong> Provide sufficient information and materials as
            reasonably requested by Falcon in the provision of services.
          </p>
        </section>

        {/* 4. Fees and Payment */}
        <section>
          <h4>4. Fees and Payment</h4>
          <p>
            <strong>4.1</strong> The fees (Fees) for the Services, that set out
            on the Website at the date an Order is accepted or such other price
            as that Falcon may agree in writing.
          </p>
          <p>
            <strong>4.2</strong> The issued Translation requests will contain
            the fees, draft review and delivery deadline as agreed upon with the
            Client.
          </p>
          <p>
            <strong>4.3</strong> Fees and charges are likely to attract VAT
            and/or other applicable taxes. The Client is liable to pay such
            taxes in all cases, even where an error may have been made when the
            parties originally agreed payment thereof.
          </p>
          <p>
            <strong>4.4</strong> Payment must be made at the time of Order by
            submitting credit or debit card details.
          </p>
          <p>
            <strong>4.5</strong> In the event of the cancellation of an Order by
            the Client, Falcon retains the right to charge the Client for the
            Services in full.
          </p>
          <p>
            <strong>4.6</strong> The cost of a translation shall be calculated
            using a count of words in the source text language, unless otherwise
            previously established in writing.
          </p>
          <p>
            <strong>4.7</strong> In case the client cancels an order, all work
            already effected shall be billed at 100%, while work which is
            suspended shall be billed at 50%.
          </p>
          <p>
            <strong>4.8</strong> Invoices must be paid within five working days
            from the date they are issued, unless otherwise is agreed upon.
          </p>
          <p>
            <strong>4.9</strong> Any late payment or failure to pay shall be
            cause for immediate collection of the entire amount due from the
            client, without previous notice or other formalities. All work in
            progress and all orders in progress shall be suspended in the event
            of late payment.
          </p>
        </section>

        <section>
          <h4>5. Contractual Terms</h4>
          <p>
            <strong>5.1</strong> The Translation Request and all other
            communication with the Client via the email to be confirmed by the
            receiving party.
          </p>
          <p>
            <strong>5.2</strong> The Client shall not, without the prior written
            consent of Falcon, at any time from the date of the Contract to the
            expiry of three months after the last date of supply of the
            Services, solicit or entice away from Falcon or employ (or attempt
            to employ) any person who is, or has been, engaged as an employee,
            consultant, or subcontractor of Falcon in the provision of the
            Services.
          </p>
          <p>
            <strong>5.3</strong> For validated reasons, if the client if not
            satisfied with a job, we will be committed to provide you with
            another version as promptly as possible at our expense. If the
            second version is still unacceptable, you shall have the right to a
            partial reimbursement. The conditions and procedure for contesting a
            job are described below.
          </p>
          <p>
            <strong>5.4</strong> If the client is not satisfied due to the delay
            in delivering the Translation services, the delay will be translated
            into the number of business days and will be calculated based on the
            total fees of the assignment. The reimbursement shall be paid within
            two working days.
          </p>
          <p>
            <strong>5.5</strong> In the event of an unsatisfied client where a
            complaint is made within the established deadlines, Falcon agrees,
            at its own expense, to deliver an edited version of the disputed
            translation within a timeframe equal to one third of that previously
            established for the job, plus one business day. This second document
            shall be used to judge the quality of the translation in order to
            establish the amount of reimbursement, at Falcon 's own discretion.
          </p>
          <p>
            <strong>5.6</strong> The Client shall be liable to pay to Falcon, on
            demand, all reasonable costs, charges, or losses sustained or
            incurred by Falcon (including, without limitation, any direct,
            indirect, or consequential losses, loss of profit and loss of
            reputation, loss or damage to property and those arising from injury
            to or death of any person and loss of opportunity to deploy
            resources elsewhere) arising directly or indirectly from the
            Client’s fraud, negligence, failure to perform or delay in the
            performance of any of its obligations under the Contract, subject to
            Falcon confirming such costs, charges and losses to the Client in
            writing.
          </p>
          <p>
            <strong>5.7</strong> Complaints shall only be taken into
            consideration if received by registered mail with return receipt
            within seven days of delivery of the job(s) or part of the job(s).
          </p>
          <p>
            <strong>5.8</strong> All complaints must be accompanied by the
            original documents, disputed translations, and a letter of
            explanation. Once the above time period has elapsed, the translation
            shall be considered correct.
          </p>
          <p>
            <strong>5.9</strong> Defects present in one part of the translation
            shall not be grounds, for any reason whatsoever, for questioning the
            entire translation. Falcon reserves the right to make modifications
            to the translation in such case.
          </p>
          <p>
            <strong>5.10</strong> Falcon reserves the right to refuse to start a
            job without an order.
          </p>
        </section>

        {/* 6. Intellectual Property Rights */}
        <section>
          <h4>6. Intellectual Property Rights</h4>
          <p>
            <strong>6.1</strong> As between the Client and Falcon, and unless
            required otherwise by law, all Intellectual Property Rights and all
            other rights in the Deliverables and the Pre-existing Materials
            shall be owned by Falcon.
          </p>
          <p>
            <strong>6.2</strong> Falcon licenses all such rights to the Client
            free of charge and on a non-exclusive, worldwide basis to such
            extent as is necessary to enable the Client to make reasonable use
            of the Deliverables and the Services. If the Contract terminates,
            this license shall automatically terminate.
          </p>
          <p>
            <strong>6.3</strong> The Client acknowledges that, where Falcon does
            not own any Pre-existing Materials, the Client’s use of rights in
            Pre-existing Materials is conditional on Falcon obtaining a written
            license (or sub-license) from the relevant licensor or licensors on
            such terms as will entitle Falcon to license such rights to the
            Client.
          </p>
        </section>

        {/* 7. Confidentiality */}
        <section>
          <h4>7. Confidentiality</h4>
          <p>
            <strong>7.1</strong> The Client shall keep in strict confidence all
            technical or commercial know-how, specifications, inventions,
            processes, or initiatives which are of a confidential nature and
            have been disclosed to the Client by Falcon, its employees, agents,
            consultants, or subcontractors and any other confidential
            information concerning Falcon’s business or its products which the
            Client may obtain.
          </p>
          <p>
            <strong>7.2</strong> The Client may disclose such information:
            <ul
              style={{
                marginLeft: "2rem",
                listStyleType: "disc",
              }}
            >
              <li>
                to its employees, officers, representatives, advisers, agents,
                or subcontractors who need to know such information for the
                purposes of carrying out the Client’s obligations under the
                Contract; and
              </li>
              <li>
                as may be required by law, court order, or any governmental or
                regulatory authority.
              </li>
            </ul>
          </p>
          <p>
            <strong>7.3</strong> The Client shall ensure that its employees,
            officers, representatives, advisers, agents, or subcontractors to
            whom it discloses such information comply with this condition.
          </p>
          <p>
            <strong>7.4</strong> The Client shall not use any such information
            for any purpose other than to perform its obligations under the
            Contract.
          </p>
          <p>
            <strong>7.5</strong> All materials, equipment and tools, drawings,
            specifications, and data supplied by Falcon to the Client (including
            Pre-existing Materials and Falcon’s Equipment) shall, at all times,
            be and remain the exclusive property of Falcon, but shall be held by
            the Client in safe custody at its own risk and maintained and kept
            in good condition by the Client until returned to Falcon, and shall
            not be disposed of or used other than in accordance with Falcon’s
            written instructions or authorisation.
          </p>
        </section>

        {/* 9. Termination and Suspension */}
        <section>
          <h4>8. Termination and Suspension</h4>
          <p>
            <strong>8.1</strong> The Contract continues as long as it takes
            Falcon to perform the Services.
          </p>
          <p>
            <strong>8.2</strong> Either Party may terminate the Contract or
            suspend the Services at any time by a written notice of termination
            or suspension to the other if that other commits a serious breach,
            or series of breaches resulting in a serious breach, of the Contract
            and the breach either cannot be fixed or is not fixed within 30 days
            of the written notice;
          </p>
          <p>
            <strong>8.3</strong> On termination of the Contract for any reason,
            any of Falcon’s respective remaining rights and liabilities will not
            be affected.
          </p>
        </section>

        {/* 9. Data Protection */}
        <section>
          <h4>9. Data Protection</h4>
          <p>
            Data Protection Legislation means the Data Protection Act 2018, and
            any legislation which amends, replaces, or re-enacts it. Personal
            Data means such data as defined under Data Protection Legislation.
          </p>
        </section>

        {/* 10. Variation */}
        <section>
          <h4>10. Variation</h4>
          <p>
            Falcon may, from time to time and without notice, change the
            Services in order to comply with any applicable safety or statutory
            requirements, provided that such changes do not materially affect
            the nature, scope of, or the charges for the Services.
          </p>
        </section>

        {/* 11. Severance, Dispute and Applicable Law */}
        <section>
          <h4>11. Severance, Dispute and Applicable Law</h4>
          <p>
            <strong>11.1</strong> If a provision of the Contract (or part of any
            provision) is found illegal, invalid or unenforceable, the parties
            shall negotiate in good faith to amend such provision such that, as
            amended, it is legal, valid and enforceable, and, to the greatest
            extent possible, achieves the parties’ original commercial
            intention.
          </p>
          <p>
            <strong>11.2</strong> All warranties, conditions, and other terms
            implied by statute or common law are, to the fullest extent
            permitted by law, excluded from the Contract.
          </p>
          <p>
            <strong>11.3</strong> In the event of a complaint, Falcon will
            address the complaint and enter correspondence with the Client with
            a view to resolving it to mutual satisfaction. Falcon will, for its
            own part, use all reasonable endeavours to settle any dispute
            amicably, and would expect its clients to act in a similar manner.
            Any dispute that cannot be settled by the parties themselves shall
            be settled by arbitration.
          </p>
          <p>
            <strong>11.4</strong> Arbitration shall be governed by the rules
            laid down for the time being by the Association of Translation
            Companies in England, which will be supplied on request. The
            decision of the arbitration tribunal shall be final and binding on
            all parties involved.
          </p>
        </section>

        {/* 12. Variation */}
        {/* <section>
          <h4>12. Variation</h4>
          <p>
            <strong>12.1</strong> Falcon may, from time to time and without
            notice, change the Services in order to comply with any applicable
            safety or statutory requirements, provided that such changes do not
            materially affect the nature, scope of, or the charges for the
            Services.
          </p>
          <p>
            <strong>12.2</strong> Subject to Condition 12.1, no variation of the
            Contract or these Conditions or of any of the documents referred to
            in them shall be valid unless it is in writing and signed by or on
            behalf of each of the parties.
          </p>
        </section> */}

        {/* 13. Waiver */}
        {/* <section>
          <h4>13. Waiver</h4>
          <p>
            <strong>13.1</strong> A waiver of any right under the Contract is
            only effective if it is in writing and it applies only to the
            circumstances for which it is given. No failure or delay by a party
            in exercising any right or remedy under the Contract or by law shall
            constitute a waiver of that (or any other) right or remedy, nor
            preclude or restrict its further exercise. No single or partial
            exercise of such right or remedy shall preclude or restrict the
            further exercise of that (or any other) right or remedy.
          </p>
          <p>
            <strong>13.2</strong> Unless specifically provided otherwise, rights
            arising under the Contract are cumulative and do not exclude rights
            provided by law.
          </p>
        </section> */}

        {/* 14. Severance */}
        {/* <section>
          <h4>14. Severance</h4>
          <p>
            <strong>14.1</strong> If a provision of the Contract (or part of any
            provision) is found illegal, invalid or unenforceable, the parties
            shall negotiate in good faith to amend such provision such that, as
            amended, it is legal, valid and enforceable, and, to the greatest
            extent possible, achieves the parties’ original commercial
            intention.
          </p>
          <p>
            <strong>14.2</strong> If any provision of the Contract (or part of
            any provision) is found by any court or other authority of competent
            jurisdiction to be invalid, illegal or unenforceable, and the
            parties have been unable to agree an amendment in accordance with
            clause 14.1, that provision or part-provision shall, to the extent
            required, be deemed not to form part of the Contract, and the
            validity and enforceability of the other provisions of the Contract
            shall not be affected.
          </p>
        </section> */}

        {/* 15. Entire Agreement */}
        {/* <section>
          <h4>15. Entire Agreement</h4>
          <p>
            <strong>15.1</strong> The Contract constitutes the whole agreement
            between the parties and supersedes all previous agreements between
            the parties relating to its subject matter.
          </p>
          <p>
            <strong>15.2</strong> Each party acknowledges that, in entering into
            the Contract, it has not relied on, and shall have no right or
            remedy in respect of, any statement, representation, assurance or
            warranty (whether made negligently or innocently) (other than for
            breach of contract).
          </p>
          <p>
            <strong>15.3</strong> Nothing in this Condition shall limit or
            exclude any liability for fraud or any liability that may not be
            lawfully excluded.
          </p>
        </section> */}

        {/* 16. Assignment */}
        {/* <section>
          <h4>16. Assignment</h4>
          <p>
            <strong>16.1</strong> The Client shall not, without the prior
            written consent of Falcon, assign, transfer, charge, mortgage,
            subcontract, or deal in any other manner with all or any of its
            rights or obligations under the Contract.
          </p>
          <p>
            <strong>16.2</strong> Falcon may at any time assign, transfer,
            charge, mortgage, subcontract or deal in any other manner with all
            or any of its rights under the Contract and may subcontract or
            delegate in any manner any or all of its obligations under the
            Contract to any third party or agent.
          </p>
          <p>
            <strong>16.3</strong> Each party that has rights under the Contract
            is acting on its own behalf and not for the benefit of another
            person.
          </p>
        </section> */}

        {/* 17. No Partnership or Agency */}
        {/* <section>
          <h4>17. No Partnership or Agency</h4>
          <p>
            Nothing in the Contract is intended to, or shall be deemed to,
            constitute a partnership or joint venture of any kind between any of
            the parties, nor constitute any party the agent of another party for
            any purpose. No party shall have authority to act as agent for, or
            to bind, the other party in any way.
          </p>
        </section> */}

        {/* 18. Notices */}
        {/* <section>
          <h4>18. Notices</h4>
          <p>
            <strong>18.1</strong> Any notice or other communication required to
            be given under the Contract shall be in writing and shall be
            delivered in writing to the other party and for the attention of the
            person specified in the Order, or as otherwise specified by the
            relevant party by notice in writing to the other party.
          </p>
          <p>
            <strong>18.2</strong> This Condition shall not apply to the service
            of any in any proceedings or other documents in any legal action.
          </p>
        </section> */}

        {/* 19. Dispute and Applicable Law */}
        {/* <section>
          <h4>19. Dispute and Applicable Law</h4>
          <p>
            <strong>19.1</strong> Falcon has a formal complaint handling
            process. In the event of a complaint, Falcon will address the
            complaint and enter correspondence with the Client with a view to
            resolving it to mutual satisfaction. Falcon will, for its own part,
            use all reasonable endeavours to settle any dispute amicably, and
            would expect its clients to act in a similar manner. Any dispute
            that cannot be settled by the parties themselves shall be settled by
            arbitration.
          </p>
          <p>
            <strong>19.2</strong> Arbitration shall be governed by the rules
            laid down for the time being by the Association of Translation
            Companies in England, which will be supplied on request. The
            decision of the arbitration tribunal shall be final and binding on
            all parties involved.
          </p>
          <p>
            <strong>19.3</strong> The Contract shall be interpreted in
            accordance with the law of England and Wales.
          </p>
        </section> */}
      </div>
    </div>
  );
}
