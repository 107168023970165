import React from "react";
import { Card, CardContent, Typography, Avatar } from "@mui/material";
import StarIcon from "@mui/icons-material/Star";

const ReviewCard = ({ review }) => {
  return (
    <Card
      sx={{
        margin: "1rem",
        padding: "1.5rem",
        borderRadius: "12px",
        boxShadow: "0 4px 10px rgba(0, 0, 0, 0.1)",
        backgroundColor: "#fff", // White background
        flex: 1,
        transition: "transform 0.3s ease-in-out",
        "&:hover": {
          transform: "translateY(-5px)",
        },
      }}
    >
      <CardContent>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            marginBottom: "1rem",
          }}
        >
          <Typography
            variant="h6"
            component="div"
            sx={{
              fontFamily: "Poppins, sans-serif",
              fontWeight: 600,
              color: "#333", // Dark text color
            }}
          >
            {review.author || "Anonymous"}
          </Typography>
        </div>
        <Typography
          variant="body1"
          color="text.secondary"
          gutterBottom
          sx={{
            fontFamily: "Poppins, sans-serif",
            fontSize: "14px",
            color: "#666", // Secondary text color
          }}
        >
          {review.review}
        </Typography>
        <div
          style={{ display: "flex", alignItems: "center", marginTop: "1rem" }}
        >
          {[...Array(5)].map((_, i) => (
            <StarIcon
              key={i}
              sx={{
                color: i < review.rate ? "#4a259b" : "#ddd", // Custom star color
              }}
            />
          ))}
        </div>
      </CardContent>
    </Card>
  );
};

export default ReviewCard;
