import { useState } from "react";
import { useApi } from "./useAPI";
import { notify } from "../components/Toast/Toast";

export const useForm = (endpoint, method = "POST") => {
  const [formValues, setFormValues] = useState({});

  const { mutate, isLoading } = useApi(endpoint, method);

  const handleChange = (e) => {
    setFormValues({ ...formValues, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    console.log(formValues);
    mutate(formValues, {
      onSuccess: (response) => {
        const successMessage =
          response.message || "Form submitted successfully!";

        notify(successMessage, "fas fa-check-circle");

        setFormValues({});
      },
      onError: (error) => {
        console.log(error);
        const errorMessage =
          error.response.data.errors[0] ||
          error.response.data.error ||
          "Error submitting form.";
        console.log(errorMessage);

        notify(errorMessage, "fas fa-exclamation-circle");
      },
    });
  };

  return { formValues, handleChange, handleSubmit, isLoading };
};
