import React, { useState } from "react";
import { Pagination } from "@mui/material";
import ReviewCard from "../components/ReviewCard/ReviewCard";

const ReviewsSection = ({ reviews }) => {
  const reviewsPerPage = 6; // Number of reviews per page
  const [currentPage, setCurrentPage] = useState(1);

  // Calculate reviews for the current page
  const indexOfLastReview = currentPage * reviewsPerPage;
  const indexOfFirstReview = indexOfLastReview - reviewsPerPage;
  const currentReviews = reviews?.slice(indexOfFirstReview, indexOfLastReview);

  // Handle Pagination Change
  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  return (
    <>
      {reviews && (
        <div className="py-5  ">
          <h1 className="text-center fontt">What Our Customers Say</h1>
          <div className="container">
            <div className="row ">
              {currentReviews?.map((review) => (
                <div
                  key={review.id}
                  className="col-md-4 mb-3 d-flex justify-content-center"
                >
                  <ReviewCard review={review} />
                </div>
              ))}
            </div>
            {/* Customized Pagination */}
            <div className="d-flex justify-content-center mt-4">
              <Pagination
                count={Math.ceil(reviews?.length / reviewsPerPage)}
                page={currentPage}
                onChange={handlePageChange}
                color="primary" // Use custom color defined below
                sx={{
                  "& .MuiPaginationItem-root": {
                    color: "#4a259b", // Default text color for pagination items
                  },
                  "& .MuiPaginationItem-root.Mui-selected": {
                    backgroundColor: "#4a259b", // Background for selected page
                    color: "#fff", // Text color for selected page
                  },
                  "& .MuiPaginationItem-ellipsis": {
                    color: "#4a259b", // Ellipsis color
                  },
                  "&:hover": {
                    "& .MuiPaginationItem-root.Mui-selected": {
                      backgroundColor: "#4a259b", // Background color on hover
                      color: "#fff", // Text color on hover
                    },
                  },
                }}
              />
            </div>
          </div>
        </div>
      )}{" "}
    </>
  );
};

export default ReviewsSection;
