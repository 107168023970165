import { useMutation, useQueryClient } from "react-query";
import axios from "axios";

const apiClient = axios.create({
  baseURL: "https://api.falcontranslation.co.uk/api",
});

export const useApi = (endpoint, method = "GET") => {
  const queryClient = useQueryClient();

  const mutation = useMutation(
    async (data) => {
      const response = await apiClient({
        url: endpoint,
        method,
        data,
      });
      console.log(response.data);
      return response.data;
    },
    {
      onError: (error) => {
        // Handle errors
        console.error("API Error:", error);
      },
      onSuccess: () => {
        // Invalidate queries if needed
        queryClient.invalidateQueries([endpoint]);
      },
    }
  );

  return mutation;
};
