import React, { useEffect, useRef, useState } from "react";
import axios from "axios";
import Swal from "sweetalert2";
import { Link, useNavigate } from "react-router-dom";
import { useForm } from "../../hooks/useForm";

export default function Quote() {
  const { formValues, handleChange, handleSubmit, isLoading } =
    useForm("/emails");

  const errorsRef = useRef(null);
  const formRef = useRef(null);

  const handleCancelClick = () => {
    window.location.href = "/";
  };
  return (
    <div>
      <div className="container py-5" ref={errorsRef}>
        <h1 className="" style={{ textAlign: "center", marginBottom: "20px" }}>
          Fast Quote
        </h1>
        <div
          style={{
            display: "flex",
          }}
        >
          <div>
            <p
              className="text-size gothic"
              style={{ display: "flex", alignItems: "start" }}
            >
              <span className="text" style={{ flex: "1" }}>
                Let Falcon Translations be your trusted partner for all your
                translation needs and we are looking forward to supporting you
                through our services.
              </span>
            </p>
            <p className="text-size gothic">
              Contact us today for a free consultation and a quote tailored to
              your specific requirements and we will get back to you within 15
              to 30 minutes during working hours.
            </p>
          </div>
        </div>
      </div>
      <form onSubmit={handleSubmit} ref={formRef}>
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="form-group mb-3">
                <label className="gothic" htmlFor="name">
                  Name
                </label>
                <input
                  type="text"
                  className="form-control"
                  name="client_name"
                  value={formValues.name}
                  onChange={handleChange}
                />
              </div>
              <div className="form-group mb-3">
                <label className="gothic" htmlFor="email">
                  Email
                </label>
                <input
                  type="email"
                  className="form-control"
                  name="client_email"
                  value={formValues.email}
                  onChange={handleChange}
                />
              </div>
              <div className="form-group mb-3">
                <label className="gothic" htmlFor="phone">
                  Phone
                </label>
                <input
                  type="text"
                  className="form-control"
                  name="client_phone"
                  value={formValues.phone}
                  onChange={handleChange}
                />
              </div>
              <div className="form-group mb-3">
                <label className="gothic" htmlFor="phone">
                  Source Language
                </label>
                <input
                  type="text"
                  className="form-control"
                  name="source_language"
                  value={formValues.source_language}
                  onChange={handleChange}
                />
              </div>
              <div className="form-group mb-3">
                <label className="gothic" htmlFor="phone">
                  Target Language
                </label>
                <input
                  type="text"
                  className="form-control"
                  name="target_language"
                  value={formValues.target_language}
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className="form-group mb-3">
              <label className="gothic" htmlFor="message">
                Notes
              </label>
              <textarea
                className="form-control"
                name="notes"
                rows="5"
                onChange={handleChange}
              ></textarea>
            </div>
            {/* <div className="form-group mb-3">
              <label className="gothic">Files</label>
              <input
                type="file"
                multiple
                className="form-control gothic"
                name="files"
                onChange={handleChange}
              />
            </div> */}
          </div>
        </div>
        <div
          className="container text-center py-5"
          style={{ display: "flex", justifyContent: "center", gap: "10px" }}
        >
          <button
            type="submit"
            className="btn btn-violet fs-6 py-2 px-3 text-center gothic"
            disabled={isLoading}
          >
            {isLoading ? "Submitting..." : "Submit"}
          </button>
          <button
            type="button"
            className="btn btn-violet fs-6 py-2 px-3 text-center gothic"
            onClick={handleCancelClick}
          >
            Cancel
          </button>
        </div>
      </form>
    </div>
  );
}
