import toast from "react-hot-toast";
import "./Toast.css";

export const notify = (message, iconClass) => {
  toast.custom(
    (t) => (
      <div
        className={`custom-toast ${t.visible ? "show" : "hide"}`}
        style={{ animation: t.visible ? "fadeIn 0.3s" : "fadeOut 0.3s" }}
      >
        <div className="icon">
          <i className={iconClass}></i>
        </div>
        <div className="message">{message}</div>
      </div>
    ),
    { duration: 4000 }
  );
};
